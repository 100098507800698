<template>
  <header
    class="pt-10 text-center"
    :class="{ 'pt-20': $route.name === 'Home' }"
  >
    <h1
      @click="$router.push({ name: 'Home' })"
      class="text-2xl font-black text-gray-600 cursor-pointer"
      :class="{ 'hover:underline': $route.name != 'Home' }"
    >
      Signed Handshake Zone Hosting
    </h1>
  </header>

  <router-view @zone-select="onZoneSelect" :zone="zone" />

  <footer class="h-12 px-20 flex items-center justify-around text-sm">
    <span>Copyright © 2021</span>
    <a href="https://blek.ga" target="_blank" rel="noopener noreferrer"
      >Made with ♥️ by rithvik/</a
    >
  </footer>
</template>

<script>
export default {
  data() {
    return {
      zone: '',
    }
  },
  methods: {
    onZoneSelect(zone) {
      console.log(zone)
      this.zone = zone
      this.$router.push({ name: 'ZonePage' })
    },
  },
}
</script>
<style lang="scss"></style>
