<template>
  <div
    class="w-full py-8 rounded-md border-2 border-gray-300 border-dashed flex flex-col text-center cursor-pointer text-gray-500 hover:text-gray-600"
    @click="$refs.fileInput.click()"
  >
    <svg
      class="h-12 fill-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 489.4 489.4"
    >
      <path
        d="M382.4 422.75H277.4v-106.1h34.7c8.8 0 14-10 8.8-17.2l-67.5-93.4c-4.3-6-13.2-6-17.5 0l-67.5 93.4c-5.2 7.2-.1 17.2 8.8 17.2h34.7v106.1H94.3c-52.5-2.9-94.3-52-94.3-105.2 0-36.7 19.9-68.7 49.4-86-2.7-7.3-4.1-15.1-4.1-23.3 0-37.5 30.3-67.8 67.8-67.8 8.1 0 15.9 1.4 23.2 4.1 21.7-46 68.5-77.9 122.9-77.9 70.4.1 128.4 54 135 122.7 54.1 9.3 95.2 59.4 95.2 116.1 0 60.6-47.2 113.1-107 117.3z"
      />
    </svg>
    <span>Upload your signed zone file</span>
    <input
      type="file"
      class="hidden"
      ref="fileInput"
      @change="handleFileChange"
    />
  </div>
</template>

<script>
export default {
  name: 'FileSelect',
  props: {
    file: File,
  },
  methods: {
    handleFileChange(e) {
      this.$emit('on-select', e.target.files[0])
    },
  },
}
</script>

<style scoped lang="scss"></style>
