<template>
  <div class="px-6 md:px-20">
    <p class="mt-2 text-gray-500 text-center">
      Host signed zones without bothering<br />
      with setting up cloud VMs and nameservers
    </p>

    <FileSelect class="mt-16" :file="zoneFile" @on-select="onFileSelect" />

    <p class="mt-8 text-sm text-gray-500 text-center">
      Not sure what to do?<br />
      <router-link :to="{ name: 'GetStarted' }" class="underline"
        >Get Started</router-link
      >
    </p>
    <p class="mt-8 text-sm text-gray-500 text-center">
      Don’t have a zone file?<br />
      <router-link :to="{ name: 'CreateZone' }" class="underline"
        >create one now</router-link
      >
    </p>

    <section class="mt-16 grid md:grid-cols-2 text-gray-800">
      <div class="mb-6 pr-10">
        <h3 class="text-3xl font-medium text-gray-600">Why?</h3>
        <p class="mt-4 font-light leading-5">
          Because
          <a
            href="https://www.cloudflare.com/dns/dnssec/how-dnssec-works/"
            target="_blank"
            rel="noopener noreferrer"
            class="underline"
            >DNSSEC</a
          >
          is a pain.<br />
          It’s great, but hard to set up and maintain.<br /><br />
          By enabling DNSSEC, it’s possible to add HTTPS to handshake names
          using
          <a
            href="https://en.wikipedia.org/wiki/DNS-based_Authentication_of_Named_Entities"
            target="_blank"
            rel="noopener noreferrer"
            class="underline"
            >DANE</a
          >.<br /><br />
          Other records can also be set for Mail, Redirects, etc.
        </p>
      </div>
      <div class="mb-6 pr-10">
        <h3 class="text-3xl font-medium text-gray-600">Security?</h3>
        <p class="mt-4 font-light leading-5">
          The zone is signed on your computer and the private keys are not
          uploaded.<br /><br />
          As any application that validates DNSSEC will look for a corresponding
          DS record on-chain, it’s impossible for the zone to be modified
          without access to the private key.
        </p>
      </div>
      <div class="mb-6 pr-10">
        <h3 class="text-3xl font-medium text-gray-600">How much $$?</h3>
        <p class="mt-4 font-light leading-5">
          This service is free while in beta, and will probably be priced at 3
          HNS per month per name once released.
        </p>
      </div>
      <div class="mb-6 pr-10">
        <h3 class="text-3xl font-medium text-gray-600">What do I get?</h3>
        <p class="mt-4 font-light leading-5">
          The ability to set any number of DNS records and of any type for any
          number of subdomains.
        </p>
      </div>
    </section>

    <section
      class="mt-16 -mx-6 md:-mx-20 py-12 text-center text-gray-100 bg-gray-800"
    >
      <h2 class="text-3xl font-medium">
        DNSSECify your domain now!
      </h2>
      <router-link
        :to="{ name: 'GetStarted' }"
        class="mt-6 px-4 py-2 inline-block text-gray-800 bg-white rounded-md"
        >Get Started</router-link
      >
    </section>
  </div>
</template>

<script>
import FileSelect from '@/components/FileSelect.vue'

export default {
  name: 'Home',
  components: {
    FileSelect,
  },
  data() {
    return {
      zoneFile: null,
    }
  },
  methods: {
    async onFileSelect(file) {
      console.log(file)
      this.$emit('zone-select', await file.text())
    },
  },
}
</script>
